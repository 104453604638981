exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-aboutus-js": () => import("./../../../src/pages/sections/Aboutus.js" /* webpackChunkName: "component---src-pages-sections-aboutus-js" */),
  "component---src-pages-sections-landing-js": () => import("./../../../src/pages/sections/Landing.js" /* webpackChunkName: "component---src-pages-sections-landing-js" */),
  "component---src-pages-sections-market-presence-js": () => import("./../../../src/pages/sections/MarketPresence.js" /* webpackChunkName: "component---src-pages-sections-market-presence-js" */),
  "component---src-pages-sections-our-products-js": () => import("./../../../src/pages/sections/OurProducts.js" /* webpackChunkName: "component---src-pages-sections-our-products-js" */),
  "component---src-pages-sections-plantprotein-js": () => import("./../../../src/pages/sections/Plantprotein.js" /* webpackChunkName: "component---src-pages-sections-plantprotein-js" */),
  "component---src-pages-sections-what-we-do-js": () => import("./../../../src/pages/sections/WhatWeDo.js" /* webpackChunkName: "component---src-pages-sections-what-we-do-js" */)
}

